import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _dd74fadc = () => interopDefault(import('../src/pages/background-remover.vue' /* webpackChunkName: "pages/background-remover" */))
const _f53481e0 = () => interopDefault(import('../src/pages/face-swap.vue' /* webpackChunkName: "pages/face-swap" */))
const _77ec7175 = () => interopDefault(import('../src/pages/image-variations.vue' /* webpackChunkName: "pages/image-variations" */))
const _061c5bdf = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _75d7c811 = () => interopDefault(import('../src/pages/predictions.vue' /* webpackChunkName: "pages/predictions" */))
const _3c34af93 = () => interopDefault(import('../src/pages/pricing.vue' /* webpackChunkName: "pages/pricing" */))
const _4d8a4c5a = () => interopDefault(import('../src/pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _5d09a5ca = () => interopDefault(import('../src/pages/style-transfer.vue' /* webpackChunkName: "pages/style-transfer" */))
const _1ddb2c48 = () => interopDefault(import('../src/pages/terms-of-service.vue' /* webpackChunkName: "pages/terms-of-service" */))
const _7f03cf5a = () => interopDefault(import('../src/pages/video-scriptwriter.vue' /* webpackChunkName: "pages/video-scriptwriter" */))
const _a8519bd0 = () => interopDefault(import('../src/pages/video-upscaler.vue' /* webpackChunkName: "pages/video-upscaler" */))
const _a3aa3586 = () => interopDefault(import('../src/pages/checkout/success.vue' /* webpackChunkName: "pages/checkout/success" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/background-remover",
    component: _dd74fadc,
    name: "background-remover___en"
  }, {
    path: "/face-swap",
    component: _f53481e0,
    name: "face-swap___en"
  }, {
    path: "/image-variations",
    component: _77ec7175,
    name: "image-variations___en"
  }, {
    path: "/ja",
    component: _061c5bdf,
    name: "index___ja"
  }, {
    path: "/ko",
    component: _061c5bdf,
    name: "index___ko"
  }, {
    path: "/predictions",
    component: _75d7c811,
    name: "predictions___en"
  }, {
    path: "/pricing",
    component: _3c34af93,
    name: "pricing___en"
  }, {
    path: "/privacy-policy",
    component: _4d8a4c5a,
    name: "privacy-policy___en"
  }, {
    path: "/style-transfer",
    component: _5d09a5ca,
    name: "style-transfer___en"
  }, {
    path: "/terms-of-service",
    component: _1ddb2c48,
    name: "terms-of-service___en"
  }, {
    path: "/video-scriptwriter",
    component: _7f03cf5a,
    name: "video-scriptwriter___en"
  }, {
    path: "/video-upscaler",
    component: _a8519bd0,
    name: "video-upscaler___en"
  }, {
    path: "/zh-hant",
    component: _061c5bdf,
    name: "index___zh-hant"
  }, {
    path: "/checkout/success",
    component: _a3aa3586,
    name: "checkout-success___en"
  }, {
    path: "/ja/background-remover",
    component: _dd74fadc,
    name: "background-remover___ja"
  }, {
    path: "/ja/face-swap",
    component: _f53481e0,
    name: "face-swap___ja"
  }, {
    path: "/ja/image-variations",
    component: _77ec7175,
    name: "image-variations___ja"
  }, {
    path: "/ja/predictions",
    component: _75d7c811,
    name: "predictions___ja"
  }, {
    path: "/ja/pricing",
    component: _3c34af93,
    name: "pricing___ja"
  }, {
    path: "/ja/privacy-policy",
    component: _4d8a4c5a,
    name: "privacy-policy___ja"
  }, {
    path: "/ja/style-transfer",
    component: _5d09a5ca,
    name: "style-transfer___ja"
  }, {
    path: "/ja/terms-of-service",
    component: _1ddb2c48,
    name: "terms-of-service___ja"
  }, {
    path: "/ja/video-scriptwriter",
    component: _7f03cf5a,
    name: "video-scriptwriter___ja"
  }, {
    path: "/ja/video-upscaler",
    component: _a8519bd0,
    name: "video-upscaler___ja"
  }, {
    path: "/ko/background-remover",
    component: _dd74fadc,
    name: "background-remover___ko"
  }, {
    path: "/ko/face-swap",
    component: _f53481e0,
    name: "face-swap___ko"
  }, {
    path: "/ko/image-variations",
    component: _77ec7175,
    name: "image-variations___ko"
  }, {
    path: "/ko/predictions",
    component: _75d7c811,
    name: "predictions___ko"
  }, {
    path: "/ko/pricing",
    component: _3c34af93,
    name: "pricing___ko"
  }, {
    path: "/ko/privacy-policy",
    component: _4d8a4c5a,
    name: "privacy-policy___ko"
  }, {
    path: "/ko/style-transfer",
    component: _5d09a5ca,
    name: "style-transfer___ko"
  }, {
    path: "/ko/terms-of-service",
    component: _1ddb2c48,
    name: "terms-of-service___ko"
  }, {
    path: "/ko/video-scriptwriter",
    component: _7f03cf5a,
    name: "video-scriptwriter___ko"
  }, {
    path: "/ko/video-upscaler",
    component: _a8519bd0,
    name: "video-upscaler___ko"
  }, {
    path: "/zh-hant/background-remover",
    component: _dd74fadc,
    name: "background-remover___zh-hant"
  }, {
    path: "/zh-hant/face-swap",
    component: _f53481e0,
    name: "face-swap___zh-hant"
  }, {
    path: "/zh-hant/image-variations",
    component: _77ec7175,
    name: "image-variations___zh-hant"
  }, {
    path: "/zh-hant/predictions",
    component: _75d7c811,
    name: "predictions___zh-hant"
  }, {
    path: "/zh-hant/pricing",
    component: _3c34af93,
    name: "pricing___zh-hant"
  }, {
    path: "/zh-hant/privacy-policy",
    component: _4d8a4c5a,
    name: "privacy-policy___zh-hant"
  }, {
    path: "/zh-hant/style-transfer",
    component: _5d09a5ca,
    name: "style-transfer___zh-hant"
  }, {
    path: "/zh-hant/terms-of-service",
    component: _1ddb2c48,
    name: "terms-of-service___zh-hant"
  }, {
    path: "/zh-hant/video-scriptwriter",
    component: _7f03cf5a,
    name: "video-scriptwriter___zh-hant"
  }, {
    path: "/zh-hant/video-upscaler",
    component: _a8519bd0,
    name: "video-upscaler___zh-hant"
  }, {
    path: "/ja/checkout/success",
    component: _a3aa3586,
    name: "checkout-success___ja"
  }, {
    path: "/ko/checkout/success",
    component: _a3aa3586,
    name: "checkout-success___ko"
  }, {
    path: "/zh-hant/checkout/success",
    component: _a3aa3586,
    name: "checkout-success___zh-hant"
  }, {
    path: "/",
    component: _061c5bdf,
    name: "index___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
