import cookies from 'js-cookie';
import { getJwtPayload } from '@motionelements/core/src/services/jwt.service';

import {
  COOKIE_CURRENCY_KEY,
  // vuex-persistence keys
  STORAGE_VUEX_ACCOUNT_KEY,
  STORAGE_VUEX_CART_KEY,
  STORAGE_VUEX_FAVORITES_KEY,
} from '@motionelements/core/src/app.constants.js';

// cache jwt payload
const jwtPayload = getJwtPayload();

let store;
// Nuxt compatibility workaround
export function setStore(s) {
  store = s;
}

export function isLoggedIn() {
  return _.has(jwtPayload, 'sub');
}

export function updateGoogleTagManager(data) {
  if (!process.server && typeof window.dataLayer !== 'undefined') {
    let memberType = 'member';
    if (data.admin) {
      memberType = 'admin';
    } else if (data.reseller) {
      memberType = 'reseller';
    } else if (data.artist) {
      memberType = 'artist';
    }

    const gtmData = {
      userId: data.id,
      username: data.username,
      firstName: data.firstName,
      lastName: data.lastName,
      fullName: data.fullName,
      email: data.email,
      memberType: memberType,
      internal: data.internal,
      language: data.language,
    };

    window.dataLayer.push(gtmData);
  }
}

function mapJwtData(jwtPayload) {
  // jwtPayload not return default avatar. Set it for member and contributor
  const avatar = `https://i1.wp.com/static.moele.me/img/member/${_.get(jwtPayload, 'artist') ? 'artist' : 'member'}-01.jpg?ssl=1`;

  return {
    id: jwtPayload.sub,
    fullName: _.get(jwtPayload, 'name'),
    firstName: _.get(jwtPayload, 'given_name'),
    lastName: _.get(jwtPayload, 'family_name'),
    username: _.get(jwtPayload, 'preferred_username'),
    language: _.get(jwtPayload, 'language', 'en'),
    currency: _.get(jwtPayload, 'currency', 'USD'),
    email: _.get(jwtPayload, 'email'),
    emailVerified: _.get(jwtPayload, 'email_verified'),
    avatar: {
      url: _.get(jwtPayload, 'picture') || avatar,
    },
    subscriptions: _.get(jwtPayload, 'subscriptions'),
    artist: _.get(jwtPayload, 'artist'),
    internal: _.get(jwtPayload, 'internal', false),
  };
}

// initAccount via API
export function initAccount(vm) {
  if (isLoggedIn() && jwtPayload) {
    // map data from standard JWT fields
    const data = mapJwtData(jwtPayload);

    // jwt id
    const jwtId = _.get(jwtPayload, 'jti');
    // use as session id
    store.commit('user/setJwtId', jwtId);
    // set jwt member for temp
    store.commit('user/setJwtMember', data);

    updateGoogleTagManager(data);

    store.dispatch('user/setCoins');

    const rollbarPerson = {
      id: data.id, // required
      username: data.username,
      email: data.email,
    };
    vm.$rollbar.configure({
      payload: {
        person: rollbarPerson,
      },
    });
  } else {
    store.commit('user/setCoins', 100);
  }

  if (!isLoggedIn()) {
    // clear account session storage
    // set final currency from cookie
    if (!process.server) {
      sessionStorage.removeItem(STORAGE_VUEX_ACCOUNT_KEY);
    }
    const cookieCurrencyCode = cookies.get(COOKIE_CURRENCY_KEY);
    if (cookieCurrencyCode) {
      store.dispatch('user/setCurrencyCode', cookieCurrencyCode);
    }
    sessionStorage.removeItem(STORAGE_VUEX_CART_KEY);
    sessionStorage.removeItem(STORAGE_VUEX_FAVORITES_KEY);
    return null;
  }
}
